<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="7" xs="3" md="3">
          <h1 class="primary--text">Chat</h1>
        </v-col>
      </v-row>
    </v-col>
  
    <v-row class="mt-0">
      <v-col 
        cols="12"
        class="px-5 py-0"
        :class="{ 'app-mobile': isDevice, 'app-mobile-dark': theme === 'dark' }"
      >
        <span v-if="showOptions" :class="{ 'user-logged-dark': theme === 'dark' }"></span>

        <chat-container
          v-if="showChat"
          :user_ids="chat_ids"
          :current-user-id="currentUserId"
          :currentUserName="currentUserName"
          :theme="theme"
          :is-device="isDevice"
        />
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as firestoreService from '@/database/firestore'
import * as storageService from '@/database/storage'
import { LOAD_CHAT_IDS } from "@/store/_actiontypes";

import ChatContainer from './ChatContainer'

export default {
  components: {
    ChatContainer
  },
  data(){
    return {
      theme: 'dark',
      showChat: true,
      users: [],
      currentUserId: '',
      currentUserName: '',
      isDevice: false,
      showDemoOptions: true,
      updatingData: false
    }
  },

  computed: {
    ...mapState({
      chat_ids: (state) => state.account.chatIds,
    }),

    showOptions(){
      this.$vuetify.theme.dark = this.$tools.getLocal('settings').theme.int;
      this.theme = this.$tools.getLocal('settings').theme.str;

      return !this.isDevice || this.showDemoOptions
    }
  },

  watch: {
    currentUserId(){
      this.showChat = false
      setTimeout(() => (this.showChat = true), 150)
    }
  },

  mounted(){
    this.isDevice = window.innerWidth < 500
    window.addEventListener('resize', ev => {
      if (ev.isTrusted) this.isDevice = window.innerWidth < 500
    });
  },

  created(){
    this.getChatIds();
    this.createUser();
  },

  methods: {
    ...mapActions("account", [LOAD_CHAT_IDS]),

    getChatIds(){
      this.LOAD_CHAT_IDS({cid:null}).then((res)=>{}).catch(()=>{});
    },

    async createUser(){
      this.updatingData = true;
      let root_user = this.$tools.getEnv("VUE_APP_CHAT_ID");
      let user_data = this.$tools.getLoggedInUserInfo();
      let user_id = user_data.sub;
      let username = user_data.act;

      const data = await firestoreService.getUser(user_id)
      if(data.hasOwnProperty('username') && data['username']==username){
        console.log('Already created!');
      } else{
        await firestoreService.addIdentifiedUser(user_id, {
          _id: user_id,
          username: username
        })
        await firestoreService.addRoom(user_id, {
          users: [user_id, root_user],
          lastUpdated: new Date()
        })
      }

      this.currentUserId = user_data.sub;
      this.currentUserName = user_data.act;
      this.updatingData = false
    },
  }
}
</script>

<style lang="scss">
body {
  background: #fafafa;
  margin: 0;
}

input {
  -webkit-appearance: none;
}

.app-mobile {
  padding: 0;

  &.app-mobile-dark {
    background: #131415;
  }

  .user-logged {
    margin: 10px 5px 0 10px;
  }

  select {
    margin: 10px 0;
  }
}

.vac-room-header{
  z-index: 0 !important;
}

select {
  height: 20px;
  outline: none;
  border: 1px solid #e0e2e4;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 20px;
}
.vac-box-search > .vac-svg-button{
  display: none !important;
}
</style>
